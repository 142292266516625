<template>
    <div class="back">
        <span @click="back">返回</span>
        <span class="bread-crumb">当前操作：<slot name="breadCrumb"></slot></span>
    </div>
</template>

<script>
  export default {
    name: "index",
    methods:{
      back(){
        this.$router.go(-1);
      },
    }
  }
</script>

<style scoped lang="less">
    .back {
        padding: 10px 40px 4px;
        margin-bottom: -14px;
        font-size: 14px;
        span:first-of-type{
            cursor: pointer;
            color: #2f8ed2;
            padding-left: 20px;
            background: url("../../assets/images/03fanhui.png") no-repeat left center;
            background-size: 14px auto;
            margin-right: 20px;
        }
        .bread-crumb{
            color: #666;
        }
    }
</style>