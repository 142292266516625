<template>
    <div class="certificate-header">
        <div class="logo-navs">
            <img src="../assets/images/03top-logo.png" alt="">
            <slot name="navs"></slot>
        </div>
        <div class="header-user-info">
             <div class="user-icon"></div>
             <div class="user-name">{{userName}}</div>
<!--             <div @click="editPasswordFlag=true" class="edit-password">修改密码</div>-->
            <div @click="quitApp" class="quit-btn">退出</div>
        </div>
        <!--修改密码-->
        <EditPassword ref="editPwd" @closeEditPwd="editPasswordFlag=false" :editPasswordFlag="editPasswordFlag"></EditPassword>
    </div>
</template>

<script>
  import EditPassword from './EditPassword'
  export default {
    name: "index",
    data(){
      return{
        userName:JSON.parse(localStorage.getItem('userInfo'))['chName'] || '-',
        editPasswordFlag:false,
      }
    },
    created() {
      // this.$eventBus.$on("userName",(msg)=>{
      //   this.userName=msg;
      //   localStorage.setItem('userName',msg);
      // })
    },
    components:{
      EditPassword
    },
    methods:{
      quitApp(){
        localStorage.clear();
        this.$router.push('/login');
      },
    },
  }
</script>

<style scoped lang="less">
    .certificate-header {
        background: #2f8ed2;
        height: 70px;
        padding: 0 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-navs{
            display: flex;
            align-items: center;
            & > img {
                display: block;
            }
            .navs{
                margin-left: 50px;
                a{
                    height: 66px;
                    display: block;
                    line-height: 66px;
                    color: #fff;
                    box-sizing: border-box;
                    border-bottom: 2px solid #fff;
                }
            }
        }



        .header-user-info {
            display: flex;
            align-items: center;

            .user-icon {
                width: 36px;
                height: 36px;
                border-radius: 100%;
                background: url("../assets/images/user-header.png") no-repeat;
                background-size: cover;
                overflow: hidden;
            }
            .user-name,.edit-password,.quit-btn{
                color: #FFFFFF;
                font-size: 14px;
                padding: 0 10px;
            }
            .edit-password,.quit-btn{
                cursor: pointer;
            }

        }
    }

</style>