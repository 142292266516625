<template>
  <div class="page-404">
    <img src="../../assets/images/404.png" alt="">
    <span>资源访问错误</span>
  </div>
</template>

<script>
export default {
  name: "404",
}
</script>

<style scoped lang="less">
.page-404 {
  text-align: center;
  position: relative;

  img {
    display: block;
    margin: 20px auto 0px;
  }

  span {
    color: red;
    text-align: center;
  }

  .button-404 {
    display: block;
    margin: 20px auto;
    color: #3a8ee6;
    border-color: #3a8ee6;
    background-color: #ecf5ff;
  }
}

</style>