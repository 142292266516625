<template>
    <el-dialog
            class="add-activity"
            title="编辑活动"
            :close-on-click-modal="false"
            append-to-body
            :before-close="handleClose"
            center
            :visible.sync="editActivityFlag"
            width="30%"
    >
        <el-form :model="ruleForm"  :rules="rules" ref="editRuleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="年度：" prop="yearName">
                <el-date-picker
                        value-format="yyyy"
                        :editable="false"
                        v-model="ruleForm.yearName"
                        type="year"
                        placeholder="选择年">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="活动名称：" prop="activityName">
                <el-input
                        type="text"
                        maxlength="50"
                        show-word-limit
                        placeholder="输入活动名称"
                        v-model.trim="ruleForm.activityName">
                </el-input>
            </el-form-item>
            <el-form-item label="举办单位：" prop="companyName">
                <el-input
                        type="text"
                        placeholder="输入举办单位"
                        v-model.trim="ruleForm.companyName"
                        maxlength="50"
                        show-word-limit>
                </el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
             <el-button  @click="sureAddActivity"  type="primary">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {activityInfo,saveActivity} from '../../request/api/common'
  export default {
    name: "index",
    data() {
      return {
        ruleForm:{
          yearName:'',
          activityName:'',
          companyName:'',
          id:null
        },
        rules:{
          yearName: [
            { required: true, message: '请选择年度', trigger: 'change' },
          ],
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在50以下', trigger: 'blur' }
          ],
          companyName: [
            { required: true, message: '请输入举办单位', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在50以下', trigger: 'blur' }
          ],
        }
      }
    },
    props:['editActivityFlag'],
    methods:{
      getActivityInfo(id){
        this.ruleForm.id=id;
        activityInfo({id}).then(({data})=>{
          const {activityInfo}=data;
           this.ruleForm.yearName=activityInfo.yearName+'';
           this.ruleForm.activityName=activityInfo.activityName;
           this.ruleForm.companyName=activityInfo.companyName;
        })
      },
      sureAddActivity(){
        this.$refs.editRuleForm.validate((valid) => {
          if(valid){
            saveActivity({...this.ruleForm}).then((m)=>{
              if(m.resultCode==-1){
                this.$message({
                  message: m.data.msg,
                  type: 'error'
                })
                return
              }else if(m.resultCode==0){
                this.$message({
                  message: '编辑活动成功',
                  type: 'success'
                });
                this.$refs.editRuleForm.resetFields();
                this.$emit('closeEditActivity');
                this.$emit('editRefreshList');

              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      },
      handleClose(){
        this.$emit('closeEditActivity');
      },
    }
  }
</script>

<style  lang="less">
    .add-activity {
         .el-dialog__header{
             text-align: left;
             .el-dialog__title{
                 font-size: 16px !important;
             }
         }
        .el-dialog__body{
            padding: 15px 25px 0 !important;
        }
    }
</style>