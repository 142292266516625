<template>
  <div class="maintain-data bg-set">
    <Header>
      <template #navs>
        <div class="navs">
          <router-link to="/adminCertificateManage">证书管理</router-link>
        </div>
      </template>
    </Header>
    <Back>
      <template #breadCrumb>维护获奖数据</template>
    </Back>
    <div class="certificate-main">
      <div class="btn-container-right">
        <el-upload
            :disabled="loadingFalse"
            style="display: inline-block;margin-right: 10px"
            name="file"
            ref="upload"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            class="upload-demo"
            :on-success="handleSuccess"
            :data="{activityId:this.activityId,access_token:this.access_token}"
            action="/api/admin/importActivityUserData"
            :limit="1">
          <el-button  :disabled="loadingFalse" class="theme-color" type="primary">导入数据</el-button>
        </el-upload>
        <el-button @click="adminExportTemplate" class="theme-color">下载导入模板</el-button>
        <el-button @click="adminExportData" class="theme-color">导出数据</el-button>
      </div>
      <template>
        <el-table
            :data="tableData"
            :cell-style="{textAlign:'center'}"
            :header-cell-style="{textAlign: 'center'}"
            border
            style="width: 100%">
          <el-table-column
              label="序号"
              width="65"
              type="index">
          </el-table-column>
          <template  v-for="(item,index) in tabHeader">
            <el-table-column
                v-if="index!=0"
                :key="index"
                :label="item">
              <template scope="{row}">
                <span @click="queryCheckCertificate(row.id)" class="theme-btn" v-if="Object.keys(row)[index]==='ch_name'">{{row[Object.keys(row)[index]]}}</span>
                <span v-else>{{row[Object.keys(row)[index]]}}</span>
              </template>
            </el-table-column>

          </template>
       </el-table>
      </template>

      <template>
        <el-pagination
            style="text-align: right;margin-top: 30px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageable.page"
            :page-sizes="pageSizes"
            :page-size="pageable.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </template>
    </div>

   <template v-if="dialogVisibleOut">
    <el-dialog
        title=""
        :before-close="closeDialog"
        :center="true"
        :visible.sync="dialogVisible"
        width="850px"
        :close-on-click-modal="false">

      <template>
        <div  ref="imageWrapper" class="user-show-certificate">
          <div class="certificate-preview">
            <img
                class="bg" alt=""
                v-if="configImg(userCertificateList[getIndexFromKey(userCertificateList,'BACKGROUND_MAP')].confVal)"
                :src="configImg(userCertificateList[getIndexFromKey(userCertificateList,'BACKGROUND_MAP')].confVal)"
            >
            <h2 :style="configObject(userCertificateList,(getIndexFromKey(userCertificateList,'THE_TITLE')))"
                class="title">{{
                userCertificateList[getIndexFromKey(userCertificateList, 'THE_TITLE')].confVal
              }}</h2>
            <div
                :style="configObject(userCertificateList,(getIndexFromKey(userCertificateList,'CERTIFICATE_NUMBER')))"
                v-show="userCertificateList[getIndexFromKey(userCertificateList,'CERTIFICATE_NUMBER')].confKeyView"
                class="cardNum">
              证书编号:{{ userCertificateList[getIndexFromKey(userCertificateList, 'CERTIFICATE_NUMBER')].confVal }}
            </div>
            <div class="name-appellation">
                  <span
                      :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'THE_DISPLAY_NAME'))"
                      class="name">{{
                      userCertificateList[getIndexFromKey(userCertificateList, 'THE_DISPLAY_NAME')].confVal
                    }}  <em></em></span>
              <span
                  v-show="userCertificateList[getIndexFromKey(userCertificateList, 'APPELLATION')].confVal"
                  :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'APPELLATION'))"
                  class="appellation">{{
                  userCertificateList[getIndexFromKey(userCertificateList, 'APPELLATION')].confVal
                }}：</span>
            </div>
            <p :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'AWARD_DESCRIPTION'))"
               class="cardDesc">
              {{ userCertificateList[getIndexFromKey(userCertificateList, 'AWARD_DESCRIPTION')].confVal }}</p>
            <div :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'AWARD_NAME'))"
                 class="cardName"
                 v-show="userCertificateList[getIndexFromKey(userCertificateList,'AWARD_NAME')].confKeyView">
              {{ userCertificateList[getIndexFromKey(userCertificateList, 'AWARD_NAME')].confVal }}
            </div>
            <div
                :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'ENCOURAGE_LANGUAGE'))"
                class="encourage">
              {{ userCertificateList[getIndexFromKey(userCertificateList, 'ENCOURAGE_LANGUAGE')].confVal }}
            </div>
            <div class="bottom-preview">
              <div
                  :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'NAME_OF_THE_ENTITY'))"
                  class="companyName">
                {{ userCertificateList[getIndexFromKey(userCertificateList, 'NAME_OF_THE_ENTITY')].confVal }}
              </div>
              <div
                  :style="configObject(userCertificateList,getIndexFromKey(userCertificateList,'CERTIFIED_DATE'))"
                  class="publishDate">
                {{ userCertificateList[getIndexFromKey(userCertificateList, 'CERTIFIED_DATE')].confVal }}
              </div>
              <img class="dianzizhang"
                   v-if="configImg(userCertificateList[getIndexFromKey(userCertificateList,'ELECTRONIC_CHAPTER')].confVal)"
                   :src="configImg(userCertificateList[getIndexFromKey(userCertificateList,'ELECTRONIC_CHAPTER')].confVal)"
                   alt="">
            </div>
          </div>


        </div>
        <!--结束-->
        <div class="my-certificate-img">
          <img :src="imgSrc" alt="">
        </div>
      </template>


      <span slot="footer" class="dialog-footer">
            <el-button @click="downImage(imgSrc)" type="primary">下载证书</el-button>
            <el-button @click="closeDialog">取 消</el-button>
  </span>
    </el-dialog>
   </template>
  </div>
</template>

<script>
import {ActivityUsersList,adminCheckCertificate} from '../../request/api/common'
import Header from "../../components/Header";
import Back from '../../components/Back'
import html2canvas from "html2canvas";

export default {
  name: "maintainData",
  components: {
    Header,
    Back
  },
  data() {
    return {
      activityId: null,
      loadingFalse:false,
      tableData: [],
      tabHeader:[],
      pageable: {
        page: 1,
        size: 10,
      },
      total: 0,
      pageSizes: [10, 15, 20, 30],
      dialogVisible:false,
      dialogVisibleOut:false,
      imgSrc:'',
      userCertificateList:[],
      access_token: JSON.parse(localStorage.getItem('userInfo'))['accessToken'],
    }
  },
  mounted() {
    this.activityId = this.$route.query.activityId;
    this.$nextTick(() => {
      this.getUserList();
    })
  },
  methods: {
    handleSizeChange(val) {
      this.pageable.size = val;
      this.pageable.page = 1;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.pageable.page = val;
      this.getUserList();
    },

    //导出模板
    adminExportTemplate() {
      location.href = `/api/admin/exportActivityUserTemplate?activityId=${this.activityId}&access_token=${this.access_token}`
    },
    //导出上传数据
    adminExportData() {
      window.location.href = `/api/admin/exportActivityUserData?activityId=${this.activityId}&access_token=${this.access_token}`
    },
    beforeAvatarUpload() {
      this.loadingFalse=true;
    },
    handleSuccess(res) {
      this.loadingFalse=false;
      if (res.resultCode == 0) {
        this.$message({
          message: '导入成功',
          type: 'success'
        })
        this.getUserList();
      }else {
        this.$message({
          message: `${res.data.message}`,
          type: 'error'
        })
      }
      this.$refs.upload.clearFiles(); //上传成功之后清除历史记录
    },
    //获取人员列表
    getUserList() {
      const pageable = {page: this.pageable.page - 1, size: this.pageable.size, activityId: this.activityId}
      ActivityUsersList({...pageable}).then(({data}) => {
         this.tabHeader=data.header;
         this.tableData=data.page.content;
         this.total=parseInt(data.page.totalElements);
      })
    },
    getIndexFromKey(arr, item) {
      let newArr = arr.filter((arrItem) => {
        return arrItem.confKey == item;
      })
      return arr.indexOf(newArr[0]);
    },
    configImg(url) {
      if (process.env.NODE_ENV === 'production') {
        return url;
      } else {
        if (url.indexOf('http://zklc.xiaotunyun.com/certificate') === 0) {
          return url.replace('http://zklc.xiaotunyun.com/certificate', '')
        }
        return url
      }
    },

    configObject(arr, index) {
        return {
          fontFamily: arr[index].confFontVal,
          fontSize: arr[index].confFontsizeVal,
          color: arr[index].confColorVal,
          fontWeight: arr[index].confStrongVal == 1 ? 'bold' : 'normal',
          fontStyle: arr[index].confItalicVal == 1 ? 'italic' : 'normal'
        }
    },
    toImage() {
      if ( !this.imgSrc) {
        this.$nextTick(() => {
          html2canvas(this.$refs['imageWrapper'], {

          }).then(canvas => {
            let dataURL = canvas.toDataURL("image/png");
            this.imgSrc = dataURL;
          });
        })
      }
    },

    //管理员查看证书
    queryCheckCertificate(id){
      this.$nextTick(()=>{
        adminCheckCertificate({id}).then(({data:{list}})=>{
          this.userCertificateList=list;
          console.log(this.userCertificateList)
          this.dialogVisibleOut=true;
          this.dialogVisible=true;
          this.toImage();
        })
      })
    },
    downImage(imgSrc) {
      let a = document.createElement('a')
      a.download = name || '证书'
      // 设置图片地址
      a.href = imgSrc;
      a.click();
      this.closeDialog();
    },
    closeDialog(){
      this.dialogVisible=false;
      this.dialogVisibleOut=false;
      this.imgSrc='';
      this.userCertificateList=[];
    },
  },
}
</script>

<style scoped lang="less">
.maintain-data {
  .theme-btn{
    color: #2f8ed2;
    cursor: pointer;
  }
}
.my-certificate-img {
  width: 800px;
  height: 566px;
  margin: 30px auto 0;
  position: relative;
  display: block;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>