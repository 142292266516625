<template>
  <div class="bg-set">
    <Header>
      <template #navs>
        <div class="navs">
          <router-link to="/adminCertificateManage">证书管理</router-link>
        </div>
      </template>
    </Header>
    <Back>
      <template #breadCrumb>证书模板配置</template>
    </Back>
    <div class="template-config certificate-main">
      <div class="function-zone">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="字体">
            <el-select v-model="confFontVal" placeholder="选择字体">
<!--               <el-option label="宋体" value="STSong"></el-option>-->

              <el-option label="宋体" value="Noto Serif SC"></el-option>
              <el-option label="行书" value="Zhi Mang Xing"></el-option>
              <el-option label="楷体" value="Ma Shan Zheng"></el-option>
              <el-option label="小薇体" value="ZCOOL XiaoWei"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="字号">
            <el-select v-model="confFontsizeVal" placeholder="选择字体" :style="{width: '100px'}">
              <el-option
                  :key="index"
                  v-for="(item,index) in fontSizeArr"
                  :label="item+'px'"
                  :value="item+'px'">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="颜色">
            <el-color-picker v-model="confColorVal"></el-color-picker>
          </el-form-item>
          <el-form-item label="加粗">
            <el-select v-model="confStrongVal" placeholder="是否加粗" :style="{width: '90px'}">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="斜体">
            <el-select v-model="confItalicVal" placeholder="是否斜体" :style="{width: '90px'}">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="form-container">
        <el-form :inline="true">
          <el-radio-group v-model="type" :style="{width: '100%'}">
            <el-col :span="22">
              <el-radio label="THE_TITLE">
                <span class="title">标题</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['THE_TITLE']].confVal"></el-input>
              </el-radio>
            </el-col>
            <el-col :span="11">
              <el-radio label="NAME_OF_THE_ENTITY">
                <span class="title">单位名称</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['NAME_OF_THE_ENTITY']].confVal"></el-input>
              </el-radio>
            </el-col>
            <el-col :span="11">
              <el-radio label="CERTIFIED_DATE">
                <span class="title">颁证日期</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['CERTIFIED_DATE']].confVal"></el-input>
              </el-radio>
            </el-col>
            <el-col :span="22">
              <el-radio label="ENCOURAGE_LANGUAGE">
                <span class="title">鼓励语</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['ENCOURAGE_LANGUAGE']].confVal"></el-input>
              </el-radio>
            </el-col>
            <el-col :span="22">
              <div class="line"></div>
            </el-col>
            <el-col :span="11">
              <el-radio label="THE_DISPLAY_NAME">
                <span class="title">显示名称</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['THE_DISPLAY_NAME']].confVal"></el-input>
              </el-radio>
            </el-col>
            <el-col :span="11">
              <el-radio label="APPELLATION">
                <span class="title">称谓</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['APPELLATION']].confVal"></el-input>
              </el-radio>
            </el-col>
            <el-col :span="11">
              <el-radio label="CERTIFICATE_NUMBER">
                <span class="title">证书编号</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['CERTIFICATE_NUMBER']].confVal"></el-input>
                <el-switch
                    v-model="activityCertConfList[getIndexFromKey['CERTIFICATE_NUMBER']].confKeyView"
                    :active-value="1"
                    :inactive-value="0"
                >
                </el-switch>
              </el-radio>
            </el-col>
            <el-col :span="11">
              <el-radio label="AWARD_NAME">
                <span class="title">奖项名称</span>
                <el-input v-model="activityCertConfList[getIndexFromKey['AWARD_NAME']].confVal"></el-input>
                <el-switch
                    v-model="activityCertConfList[getIndexFromKey['AWARD_NAME']].confKeyView"
                    :active-value="1"
                    :inactive-value="0"
                >
                </el-switch>
              </el-radio>
            </el-col>
            <el-col :span="22">
              <el-radio label="AWARD_DESCRIPTION">
                <span class="title">奖项描述</span>
                <el-input :autosize="{ minRows: 3, maxRows: 5}" type="textarea"
                          v-model="activityCertConfList[getIndexFromKey['AWARD_DESCRIPTION']].confVal"></el-input>
              </el-radio>
            </el-col>
          </el-radio-group>

          <el-col class="label-width-set" :span="11">
            <el-form-item required label="电子章">
              <el-upload
                  ref="electron"
                  class="upload-demo"
                  name="file"
                  :limit="1"
                  :show-file-list="false"
                  :on-success="handleElectronSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="{access_token:this.access_token}"
                  action="/api/common/uploadFile">
                <el-button icon="el-icon-upload2">点击上传</el-button>
                <div style="color: red" slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col class="label-width-set" :span="11">
            <el-form-item required label="背景图">
              <el-upload
                  class="upload-demo"
                  name="file"
                  :limit="1"
                  ref="bg"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleBgSuccess"
                  :data="{access_token:this.access_token}"
                  action="/api/common/uploadFile">
                <el-button  icon="el-icon-upload2">点击上传</el-button>
                <div style="color: red" slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col class="label-width-set" :span="22">
            <el-button v-if="!loadingShow"  @click="saveConfig" style="float: right" type="primary">保存</el-button>
            <el-button v-if="loadingShow" :loading="loadingShow"  style="float: right" type="primary">正在保存</el-button>
          </el-col>
        </el-form>
      </div>

      <div class="show-certificate">
        <span>效果图预览:</span>
        <div :style="{backgroundImage:`url(${(activityCertConfList[getIndexFromKey['BACKGROUND_MAP']].confVal)}`}" class="certificate-preview">
          <h2 :style="configObject(getIndexFromKey['THE_TITLE'])" class="title">{{ activityCertConfList[getIndexFromKey['THE_TITLE']].confVal}}</h2>
          <div :style="configObject(getIndexFromKey['CERTIFICATE_NUMBER'])" v-show="activityCertConfList[getIndexFromKey['CERTIFICATE_NUMBER']].confKeyView" class="cardNum">证书编号:{{activityCertConfList[getIndexFromKey['CERTIFICATE_NUMBER']].confVal}}</div>
          <div class="name-appellation">
            <span :style="configObject(getIndexFromKey['THE_DISPLAY_NAME'])" class="name">
              {{ activityCertConfList[getIndexFromKey['THE_DISPLAY_NAME']].confVal}}
              <em></em>
            </span>
            <span :style="configObject(getIndexFromKey['APPELLATION'])" class="appellation">{{ activityCertConfList[getIndexFromKey['APPELLATION']].confVal}}：</span>
          </div>
          <p :style="configObject(getIndexFromKey['AWARD_DESCRIPTION'])" class="cardDesc">{{ activityCertConfList[getIndexFromKey['AWARD_DESCRIPTION']].confVal}}</p>
          <div :style="configObject(getIndexFromKey['AWARD_NAME'])" class="cardName" v-show="activityCertConfList[getIndexFromKey['AWARD_NAME']].confKeyView">{{ activityCertConfList[getIndexFromKey['AWARD_NAME']].confVal}}</div>
          <div :style="configObject(getIndexFromKey['ENCOURAGE_LANGUAGE'])" class="encourage">{{ activityCertConfList[getIndexFromKey['ENCOURAGE_LANGUAGE']].confVal}}</div>
          <div class="bottom-preview">
            <div :style="configObject(getIndexFromKey['NAME_OF_THE_ENTITY'])" class="companyName">{{ activityCertConfList[getIndexFromKey['NAME_OF_THE_ENTITY']].confVal}}</div>
            <div :style="configObject(getIndexFromKey['CERTIFIED_DATE'])" class="publishDate">{{ activityCertConfList[getIndexFromKey['CERTIFIED_DATE']].confVal}}</div>
            <img class="dianzizhang" :src="this.activityCertConfList[getIndexFromKey['ELECTRONIC_CHAPTER']].confVal" alt="">
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {certificateConfigList,saveConfig} from '../../request/api/common'
import Header from "../../components/Header";
import Back from '../../components/Back';
export default {
  name: "templateConfig",
  components: {
    Header,
    Back,
  },
  data() {
    /** type
     * title 标题
     * companyName 单位名称
     * publishDate 颁证日期
     * encourage 鼓励语
     * name 显示名称
     * appellation 称谓
     * cardNum 证书编号
     * cardName 奖项名称
     * cardDesc 奖项描述
     */
    return {
      activityId: this.$route.query.activityId,
      access_token: JSON.parse(localStorage.getItem('userInfo'))['accessToken'],
      loadingShow:false,
      type: 'THE_TITLE',  //单选默认选中选项
      activityCertConfList: [
        {
          activityId: '',
          confColorVal: '#de4c61',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '50px',
          confItalicVal: '0',
          //confKey: 'title',
          confKey: 'THE_TITLE',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '标题'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '16px',
          confItalicVal: '0',
          //confKey: 'companyName',
          confKey: 'NAME_OF_THE_ENTITY',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '单位名称'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '16px',
          confItalicVal: '0',
          //confKey: 'publishDate',
          confKey: 'CERTIFIED_DATE',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '颁证日期'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '18px',
          confItalicVal: '0',
          // confKey: 'encourage',
          confKey: 'ENCOURAGE_LANGUAGE',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '继续加油'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '18px',
          confItalicVal: '0',
          //confKey: 'name',
          confKey: 'THE_DISPLAY_NAME',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '显示姓名'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '18px',
          confItalicVal: '0',
          confKey: 'APPELLATION',
          //confKey: 'appellation',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '称谓'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '16px',
          confItalicVal: '0',
          //confKey: 'cardNum',
          confKey: 'CERTIFICATE_NUMBER',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '证书编号'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '30px',
          confItalicVal: '0',
          confKey: 'AWARD_NAME',
          //confKey: 'cardName',
          confKeyView: 1,
          confStrongVal: '1',
          confVal: '奖项名称'
        },
        {
          activityId: '',
          confColorVal: '#000000',
          confFontVal: 'Noto Serif SC',
          confFontsizeVal: '18px',
          confItalicVal: '0',
          confKey: 'AWARD_DESCRIPTION',
          // confKey: 'cardDesc',
          confKeyView: 1,
          confStrongVal: '0',
          confVal: '奖项描述'
        },
        {
          activityId: '',
          confKey: "ELECTRONIC_CHAPTER",
          confKeyName: "电子章",
          confVal: '',
        },
        {
          activityId: '',
          confKey: "BACKGROUND_MAP",
          confKeyName: "背景地图",
          confVal:'',
         // confVal:`${require('../../assets/images/zhengshu-bg-1.png')}`,
        }
      ],
    }
  },
  mounted() {
    this.getCertificateConfigList();
  },
  computed: {
    /**
     * 选中的哪一项
     * @returns {number}
     */
    chooseIndex() {
      return this.activityCertConfList.indexOf(this.activityCertConfList.find(item => {
        return item.confKey === this.type
      }))
    },
    getIndexFromKey(){
      const indexMap = {};
      this.activityCertConfList.forEach((item, index) => {
        indexMap[item.confKey] = index;
      })
      return indexMap;
    },
    fontSizeArr() {
      let fontSizeArr = [];
      for (let i = 8; i <=100; i = i + 2) {
        fontSizeArr.push(i);
      }
      return fontSizeArr;
    },
    confFontVal: {
      get() {
        return this.activityCertConfList[this.chooseIndex].confFontVal
      },
      set(val) {
        this.activityCertConfList[this.chooseIndex].confFontVal = val;
      }
    },
    confFontsizeVal: {
      get() {
        return this.activityCertConfList[this.chooseIndex].confFontsizeVal
      },
      set(val) {
        this.activityCertConfList[this.chooseIndex].confFontsizeVal = val;
      }
    },
    confColorVal: {
      get() {
        return this.activityCertConfList[this.chooseIndex].confColorVal
      },
      set(val) {
        this.activityCertConfList[this.chooseIndex].confColorVal = val;
      }
    },
    confStrongVal: {
      get() {
        return this.activityCertConfList[this.chooseIndex].confStrongVal
      },
      set(val) {
        this.activityCertConfList[this.chooseIndex].confStrongVal = val;
      }
    },
    confItalicVal: {
      get() {
        return this.activityCertConfList[this.chooseIndex].confItalicVal
      },
      set(val) {
        this.activityCertConfList[this.chooseIndex].confItalicVal = val;
      }
    },
    configObject() {
      return function (index) {
        return {
          fontFamily: this.activityCertConfList[index].confFontVal,
          fontSize: this.activityCertConfList[index].confFontsizeVal,
          color: this.activityCertConfList[index].confColorVal,
          fontWeight: this.activityCertConfList[index].confStrongVal == 1 ? 'bold' : 'normal',
          fontStyle: this.activityCertConfList[index].confItalicVal == 1 ? 'italic' : 'normal'
        }
      }
    },
  },
  methods: {
    //获取列表配置项
    getCertificateConfigList() {
      const activityId = this.$route.query.activityId;
      certificateConfigList({activityId}).then(({data}) => {
        if(data.activityCertConfList && data.activityCertConfList.length){
          this.activityCertConfList=data.activityCertConfList;
        }
      })
    },
    //图片格式及大小限制
    beforeAvatarUpload(file){
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG或者PNG格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    //电子章
    handleElectronSuccess(res) {
      //this.activityCertConfList[this.getIndexFromKey['ELECTRONIC_CHAPTER']].confVal = URL.createObjectURL(file.raw);
      this.activityCertConfList[this.getIndexFromKey['ELECTRONIC_CHAPTER']].confVal = res.data.fileUrl;
      this.$refs.electron.clearFiles(); //上传成功之后清除历史记录
    },
    //背景图片
    handleBgSuccess(res) {
      this.activityCertConfList[this.getIndexFromKey['BACKGROUND_MAP']].confVal =res.data.fileUrl;
      this.$refs.bg.clearFiles(); //上传成功之后清除历史记录
    },
    //保存配置项
    saveConfig(){
      if(!this.activityCertConfList[this.getIndexFromKey['ELECTRONIC_CHAPTER']].confVal){
        this.$message.error('请上传电子章');
        return false;
      }
      if(!this.activityCertConfList[this.getIndexFromKey['BACKGROUND_MAP']].confVal){
        this.$message.error('请上传背景图');
        return false;
      }
      this.loadingShow=true;
      let activityCertConfList=[];
      this.activityCertConfList.forEach((item)=>{
        activityCertConfList.push({...item,"activityId":this.activityId})
      })
      saveConfig(activityCertConfList,{jsondata:true}).then((data)=>{
         if(data.resultCode==0){
           this.$message({
             message: `${data.data.msg}`,
             type: 'success'
           });
           this.loadingShow=false;
           this.$router.push('/adminCertificateManage');
         }

      })
    },
  }
}
</script>
<style lang="less">
.label-width-set {
  margin-top: 20px;

  .el-form-item__label {
    width: 89px;
    font-weight: 500;
  }
}
</style>
<style scoped lang="less">
.template-config {
  overflow: hidden;
  //padding-bottom: 50px;

  .function-zone {
    padding: 10px;
    background: RGBA(241, 248, 252, 1);
    border-radius: 10px;
    margin: 10px 0;

    .el-form-item {
      margin-bottom: 0;
    }

    /deep/ .el-form-item__content {
      height: 36px;
    }
  }

  .form-container {
    .title {
      display: inline-block;
      width: 65px;
    }

    .line {
      width: calc(100% - 70px);
      height: 1px;
      background: #eee;
      display: block;
      margin: 20px 0;
    }

    /deep/ .el-radio {
      width: 100%;

      .el-input, .el-textarea {
        width: calc(100% - 160px);
      }
    }

    /deep/ .el-col {
      margin-bottom: 10px;
    }

    /deep/ .el-radio__input {
      height: 36px;
      line-height: 36px;
      display: inline-block;
    }

    .el-form-item {
      margin-bottom: 0;
    }

    /deep/ .el-switch {
      margin-left: 10px;
    }
  }


  .show-certificate {
    clear: both;
    border-top: 1px solid #eee;
    padding: 30px 0;

    & > span {
      font-weight: bold;
      font-size: 14px;
      color: #606266;
      width: 120px;
      display: inline-block;
      float: left;
      text-align: right;
    }
  }


}

</style>
