<template>
<div class="no-data">
  <img src="../../assets/images/zanwuneirong.png" alt="">
  <span>暂无内容</span>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="less">
.no-data{
  padding: 20px 0;
  text-align: center;
  span{
    display: block;
  }
}

</style>