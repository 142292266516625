<template>
  <div class="bg-set my-certificate">
    <Header>
      <template #navs>
        <div class="navs">
          <router-link to="/myCertificates">我的证书</router-link>
        </div>
      </template>
    </Header>
    <div  v-loading="loadingTrue"  element-loading-text="正在生成证书" class="my-certificate-list certificate-main">
      <el-collapse v-if="collapseList.length" accordion v-model="activeName" @change="handleChange">
        <el-collapse-item :key="index" v-for="(item,index) in collapseList" :name="index+1">
          <template slot="title">
            {{ item.activityName }}
          </template>
          <div class="certificate-collapse-body">
            <!--需要隐藏的部分开始--->
            <div ref="imageWrapper" class="user-show-certificate">
<!--              <div class="certificate-preview" :style="{backgroundImage:`url(${configImg((item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'BACKGROUND_MAP')].confVal))}`}">-->

              <div class="certificate-preview">
                <img
                    class="bg" alt=""
                    v-if="configImg(item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'BACKGROUND_MAP')].confVal)"
                    :src="configImg(item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'BACKGROUND_MAP')].confVal)"
                >
                <h2 :style="configObject(item.certConfInfoList,(getIndexFromKey(item.certConfInfoList,'THE_TITLE')))"
                    class="title">{{
                    item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'THE_TITLE')].confVal
                  }}</h2>
                <div
                    :style="configObject(item.certConfInfoList,(getIndexFromKey(item.certConfInfoList,'CERTIFICATE_NUMBER')))"
                    v-show="item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'CERTIFICATE_NUMBER')].confKeyView"
                    class="cardNum">
                  证书编号:{{ item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'CERTIFICATE_NUMBER')].confVal }}
                </div>
                <div class="name-appellation">
                  <span
                      :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'THE_DISPLAY_NAME'))"
                      class="name">{{
                      item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'THE_DISPLAY_NAME')].confVal
                    }}  <em></em></span>
                  <span
                      v-show="item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'APPELLATION')].confVal"
                      :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'APPELLATION'))"
                      class="appellation">{{
                      item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'APPELLATION')].confVal
                    }}：</span>
                </div>
                <p :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'AWARD_DESCRIPTION'))"
                   class="cardDesc">
                  {{ item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'AWARD_DESCRIPTION')].confVal }}</p>
                <div :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'AWARD_NAME'))"
                     class="cardName"
                     v-show="item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'AWARD_NAME')].confKeyView">
                  {{ item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'AWARD_NAME')].confVal }}
                </div>
                <div
                    :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'ENCOURAGE_LANGUAGE'))"
                    class="encourage">
                  {{ item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'ENCOURAGE_LANGUAGE')].confVal }}
                </div>
                <div class="bottom-preview">
                  <div
                      :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'NAME_OF_THE_ENTITY'))"
                      class="companyName">
                    {{ item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'NAME_OF_THE_ENTITY')].confVal }}
                  </div>
                  <div
                      :style="configObject(item.certConfInfoList,getIndexFromKey(item.certConfInfoList,'CERTIFIED_DATE'))"
                      class="publishDate">
                    {{ item.certConfInfoList[getIndexFromKey(item.certConfInfoList, 'CERTIFIED_DATE')].confVal }}
                  </div>
                  <img class="dianzizhang"
                       v-if="configImg(item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'ELECTRONIC_CHAPTER')].confVal)"
                       :src="configImg(item.certConfInfoList[getIndexFromKey(item.certConfInfoList,'ELECTRONIC_CHAPTER')].confVal)"
                       alt="">
                </div>
              </div>


            </div>
            <!--结束-->
            <div class="my-certificate-img">
              <img :src="item.imgSrc" alt="">
            </div>
            <el-button @click="downImage(item.imgSrc)" class="download-btn">下载证书</el-button>
          </div>
        </el-collapse-item>
      </el-collapse>
      <NoData v-if="!loadingTrue && !collapseList.length"></NoData>
    </div>
  </div>
</template>
<script>
import {userCertificateList} from '../../request/api/common'
import Header from "../../components/Header";
import NoData from '../../components/NoData'
import html2canvas from "html2canvas"

export default {
  name: "myCertificate",
  components: {
    Header,
    NoData
  },
  data() {
    return {
      activeName: 1,
      imgUrl: require('../../assets/zhengshu.png'),
      collapseList: [],
      dialogTableVisible: false,
      loadingTrue:true
    }
  },
  mounted() {
    this.getCertificateList();
  },
  computed: {
    configObject() {
      return function (arr, index) {
        return {
          fontFamily: arr[index].confFontVal,
          fontSize: arr[index].confFontsizeVal,
          color: arr[index].confColorVal,
          fontWeight: arr[index].confStrongVal == 1 ? 'bold' : 'normal',
          fontStyle: arr[index].confItalicVal == 1 ? 'italic' : 'normal'
        }
      }
    },
  },
  methods: {
    configImg(url) {
      if (process.env.NODE_ENV === 'production') {
        return url;
      } else {
        if (url.indexOf('http://zklc.xiaotunyun.com/certificate') === 0) {
          return url.replace('http://zklc.xiaotunyun.com/certificate', '')
        }
        return url
      }
    },
    getCertificateList() {
      userCertificateList().then(({data: {list: arr}}) => {
        this.loadingTrue=false;
        arr.forEach((item) => {
          this.collapseList.push({...item, "imgSrc": ''});
        })
        if(this.collapseList.length){
          this.toImage(this.activeName)
        }
      })
    },
    getIndexFromKey(arr, item) {
      let newArr = arr.filter((arrItem) => {
        return arrItem.confKey == item;
      })
      return arr.indexOf(newArr[0]);
    },
    handleChange(index) {
      this.toImage(index);
    },
    toImage(index) {
      if (index && !this.collapseList[index - 1]['imgSrc']) {

        this.$nextTick(() => {
          html2canvas(this.$refs['imageWrapper'][index - 1], {
           // useCORS: true,
            //width:800,
            //scale:2,
            //dpi:150
          }).then(canvas => {
            let dataURL = canvas.toDataURL("image/png");
            console.log(dataURL)
            this.collapseList[index - 1]['imgSrc'] = dataURL;
          });
        })
      }
    },
    SaveAs5(url, fileName){
      alert('ie浏览器');
      let bstr = atob(url.split(',')[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let blob = new Blob([u8arr],{type:'image/png'})
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    },

    downImage(imgSrc) {
      let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      //是ie内核
      if(userAgent.indexOf("Trident") > -1){
        this.$message({
          message: '此浏览器暂不支持下载功能请选择其他浏览器',
          type: 'warning'
        });
         //this.SaveAs5(imgSrc,'证书');
      }else {
        let a = document.createElement('a')
        a.download =  '证书';
        // 设置图片地址
        a.href = imgSrc;
        a.click();
      }

    },
  }
}
</script>

<style lang="less">
.my-certificate-list {
  padding: 0 !important;
  border-radius: 0 !important;

  .el-collapse-item {
    margin-bottom: 20px;
  }

  .el-collapse-item__header {
    background: url("../../assets/images/05tubiao.png") #dff7ff no-repeat 16px center;
    padding-left: 55px;
    font-size: 18px;
    color: #585858;
  }

  .el-collapse-item__wrap {
    border-bottom: none;
  }

  .certificate-collapse-body {
    padding-left: 38px;

    .my-certificate-img {
      width: 800px;
      height: 566px;
      margin: 30px auto 0;
      position: relative;
      display: block;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .download-btn {
      background: #ff8533;
      color: #fff;
      border-color: #ff8533;
      display: block;
      margin: 20px auto 0;
    }


    .user-show-certificate {
      position: fixed;
      left: -100000px;
      top: -100000px;
      width: 800px;
      height: 566px;
      margin: 30px auto 0;
    }
  }

}

</style>
