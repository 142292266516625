<template>
  <el-dialog
      class="edit-password"
      title="修改密码"
      :close-on-click-modal="false"
      append-to-body
      :before-close="handleClose"
      center
      :visible.sync="editPasswordFlag"
      width="30%"
  >
    <el-form size="medium" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="原密码：" prop="oldPwd">
        <el-input type="password" placeholder="输入旧密码" v-model.trim="ruleForm.oldPwd"></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPwd">
        <el-input type="password" placeholder="密码必须包含字母和数字8到16位" v-model.trim="ruleForm.newPwd"></el-input>
      </el-form-item>
      <el-form-item label="新密码确认：" prop="sureNewPwd">
        <el-input type="password" placeholder="密码必须包含字母和数字8到16位" v-model.trim="ruleForm.sureNewPwd"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
             <el-button size="medium" @click="surePwd" type="primary">修改完毕</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {updatePwdInfo} from '../../request/api/common'

export default {
  name: "index",
  data() {
    const validateOldPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validateNewPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入新密码'));
      } else {
        if (!(new RegExp(this.regular).test(value))) {
          return callback(new Error('密码必须包含字母和数字8到16位!'));
        } else if (this.ruleForm.sureNewPwd !== '') {
          this.$refs.ruleForm.validateField('sureNewPwd');
          callback();
        } else {
          callback();
        }
      }
    };
    const validateSureNewPwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请再次输入新密码'));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error('两次输入密码不一致!'));
      } else if (!(new RegExp(this.regular).test(value))) {
        callback(new Error('密码必须包含字母和数字8到16位!'));
      } else {
        callback();
      }
    };
    return {
      regular: '^(?![^a-zA-Z]+$)(?!\\\\D+$).{8,16}$',
      ruleForm: {
        oldPwd: '',
        newPwd: '',
        sureNewPwd: '',
      },
      rules: {
        oldPwd: [
          {validator: validateOldPwd, trigger: 'blur'}
        ],
        newPwd: [
          {validator: validateNewPwd, trigger: 'blur'}
        ],
        sureNewPwd: [
          {validator: validateSureNewPwd, trigger: 'blur'}
        ]
      }
    }
  },
  props: ['editPasswordFlag'],
  methods: {
    surePwd() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const query = {
            oldPwd: this.ruleForm.oldPwd,
            newPwd: this.ruleForm.newPwd,
            reNewPwd: this.ruleForm.sureNewPwd
          };
          updatePwdInfo(query).then((m)=>{
            if(m.resultCode==-1){
              this.$message({
                message: m.data.msg,
                type: 'error'
              })
              return
            }else if(m.resultCode==0){
              this.$message({
                message: '密码修改成功',
                type: 'success'
              });
              this.$refs.ruleForm.resetFields();
              this.$emit('closeEditPwd');
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    handleClose() {
      this.$emit('closeEditPwd');
    },
  }
}
</script>

<style lang="less">
.edit-password {
  .el-dialog__header {
    text-align: left;

    .el-dialog__title {
      font-size: 16px !important;
    }
  }

  .el-dialog__body {
    padding: 15px 25px 0 !important;
  }
}
</style>