import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue';
import CertificateManage from '../views/adminPages/certificateManage.vue';
import MaintainData from '../views/adminPages/maintainData.vue';
import TemplateConfig from '../views/adminPages/templateConfig.vue';
import MyCertificates from '../views/userPages/myCertificate';
import Page404 from '../views/errorPage/404';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { auth: [ '*' ] }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: [ '*' ] }
  },
  {
    path:'/adminCertificateManage',
    name:'AdminCertificateManage',
    component: CertificateManage,
    meta: { auth: [ 'G' ] }
  },
  {
    path:'/adminMaintainData',
    name:'AdminMaintainData',
    component: MaintainData,
    meta: { auth: [ 'G' ] }
  },
  {
    path:'/adminTemplateConfig',
    name:'AdminTemplateConfig',
    component: TemplateConfig,
    meta: { auth: [ 'G' ] }
  },
  {
    path:'/myCertificates',
    name:'MyCertificates',
    component: MyCertificates,
    meta: { auth: [ 'U'] }
  },
  {
    path:'/404',
    name:'page404',
    component: Page404,
    meta: { auth: [ '*'] }
  },
  {
    path:'*',
    name:'404',
    component: Page404,
    meta: { auth: [ '*' ] },
    redirect:'/404'
  }
]

const router = new VueRouter({
  routes
})
//路由导航守卫（初始化的时候被调用）
router.beforeEach((to, from, next) => {
  const auth = to?.meta?.auth || [];
  if(auth.includes('*')){
    next();
  } else{
    const userInfo =localStorage.userInfo ? JSON.parse(localStorage.userInfo) : { userType : '' };
    const { userType = '' } = userInfo;
    const userTypeArr = userType.split(',');
    let result = false;
    userTypeArr.forEach(item=>{
      if(auth.includes(item)){
        result = true;
      }
    })
    if(result){
      next();
    }else {
      next('/404')
    }
  }
})


export default router
