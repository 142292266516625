<template>
  <div class="login">
    <div class="login-container">
      <div  class="login-right">
        <div class="login-logo">
          <img src="../assets/images/login-logo.png" alt="">
        </div>
        <div class="main-input width-valid">
          <img class="input-logo" src="../assets/images/01-yonghu.png" alt="">
          <img class="line" src="../assets/images/01-xiegang.png" alt="">
          <input v-model.trim="userInfo.username" type="text" placeholder="用户名">
        </div>
        <div class="main-input width-valid">
          <img class="input-logo" src="../assets/images/01-shouji.png" alt="">
          <img class="line" src="../assets/images/01-xiegang.png" alt="">
          <input v-model.trim="userInfo.password" type="text" placeholder="手机号">
        </div>
        <div class="code-container width-valid">
          <div class="code-input">
            <input type="text" v-model="userInfo.imageCode" placeholder="输入验证码">
          </div>
          <div class="code-img">
            <img @click="refreshImg" :src="imgUrl" alt="">
          </div>
        </div>
        <div class="login-btn width-valid">
          <el-button v-if="!loadingShow" @click="login">登录</el-button>
          <el-button v-if="loadingShow" style="letter-spacing: 0;margin-left: 0" :loading="true">正在登录</el-button>
        </div>
      </div>
    </div>
    <div v-if="isIE" class="shadow-tip-ie">
      <div class="tip-content">
        <div class="tip-icon"><i class="el-icon-bell"></i></div>
        <p>目前暂不支持IE内核的浏览器，请您使用其他浏览器。我们正在积极改进中，非常抱歉，给您带来不便。</p>
      </div>
    </div>
    <div class="browser-list">
          支持浏览器：
          <a href="http://baoku.360.cn/soft/show/appid/105189" target="_blank">谷歌</a>、
          <a href="http://baoku.360.cn/soft/show/appid/104511" target="_blank">火狐</a>、
          <a href="https://www.apple.com/cn/safari/" target="_blank">Safari</a>、
          <a  href="https://browser.360.cn/ee/" target="_blank">360极速浏览器</a>、
          <a  href="https://www.microsoft.com/zh-cn/edge" target="_blank">Microsoft Edge</a>
    </div>
  </div>
</template>

<script>
import {userLogin} from '../request/api/common'

let imgUrl = '';
imgUrl = 'api/common/captcha.jpg?t=';

export default {
  name: "Login",
  data() {
    return {
      userInfo: {
        username: '',
        password: '',
        imageCode: '',
      },
      isIE:false,
      imgUrl: imgUrl + Date.now(),
      loadingShow:false,
    }
  },
  mounted() {
    document.onkeydown = (ev)=>{
      const event=ev ||event
      if(event.keyCode == 13){
        this.login();
      }
    }
    this.getBrowser();
  },
  methods: {
    //判断ie内核
    getBrowser(){
      let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      if(userAgent.indexOf("Trident") > -1){
         this.isIE=true;
      }
    },
    /* 获取验证码 */
    refreshImg() {
      this.imgUrl = imgUrl + Date.now();
    },
    /* 登录 */
    login() {
      this.loadingShow=true;
      if(this.isIE){
        return false
      }
      userLogin({...this.userInfo}).then((data)=>{
        this.loadingShow=false;
        if(data.resultCode===-1){
          const {data:{msg}}=data;
          this.$message.error(msg);
        }else {
          localStorage.setItem('userInfo',JSON.stringify(data.data.content));
          const {data:{content:{userType}}}=data
          if(userType==='G'){
            this.$router.push('/adminCertificateManage');
          }else if(userType==='U'){
            this.$router.push('/myCertificates');
          }
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.login {
  height: calc(100%);
  background-color: #59a9ea;
  background-image: url("../assets/images/login-bj1.png"),
  url("../assets/images/login-bj2.png");
  background-position: top left, bottom left;
  background-repeat: no-repeat;
  background-size: contain;

  .login-container {
    width: 1100px;
    height: 460px;
    border-radius: 20px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url("../assets/images/login-bg3.png") #fff no-repeat left bottom;
    background-size: auto 87%;


    .login-right {
      position: absolute;
      top: 0px;
      padding-top: 50px;
      right: 0px;
      padding-right: 60px;
      width: 410px;
      bottom: 0;

      .login-logo {
        margin-bottom: 10px;
        width: 410px;
        height: 112px;
        img{
          width: 80%;
          height: auto;
          margin: 0 auto;
          display: block;
        }
      }

      .width-valid {
        width: 356px;
        box-sizing: border-box;
      }

      .main-input {
        margin: 0 auto 12px;
        height: 44px;
        line-height: 44px;
        border-radius: 4px;
        background: #fff;
        border: 1px solid #ebeef5;

        .input-logo {
          width: 15px;
          vertical-align: middle;
          margin: 0 22px 0 16px;
        }

        .line {
          vertical-align: middle;
          margin-right: 18px;
        }

        input {
          border: none;
          outline: none;
          padding: 0;
          -webkit-box-shadow: 0 0 0 1000px #fff inset;
          width: 250px;
          /*-webkit-tap-highlight-color: rgba(255,255,255,1);*/
        }
      }

      .code-container {
        padding-top: 9px;
        height: 55px;
        margin: 0 auto 14px;

        .code-input {
          float: left;
          border-radius: 4px;
          background: #fff;
          width: 210px;
          height: 46px;
          text-align: center;
          box-sizing: border-box;
          border: 1px solid #ebeef5;


          input {
            height: 100%;
            width: 82%;
            outline: none;
            padding: 0;
            border: none;
          }
        }

        .code-img {
          width: 110px;
          height: 46px;
          float: right;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

      }

      .login-btn {
        height: 45px;
        line-height: 45px;
        margin: 0 auto;
        text-align: center;


        button {
          display: block;
          appearance: none;
          border: none;
          width: 100%;
          height: 45px;
          letter-spacing: 10px;
          background: rgba(255, 133, 51, 1);
          font-size: 18px;
          border-radius: 4px;
          font-weight: 400;
          color: #fff;
          box-shadow: 0px 5px 8px 0px rgba(13, 5, 9, 0.42);
          cursor: pointer;
        }
      }


    }



  }
  .shadow-tip-ie{
    background: rgba(0,0,0,.5);
    width: 1100px;
    height: 460px;
    border-radius: 20px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .tip-content{
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 480px;
      padding-top: 135px;
      box-sizing: border-box;
      .tip-icon{
        width: 60px;
        height: 60px;
        background: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 60px;
        padding-top: 10px;
        box-sizing: border-box;
        margin: 0 auto;
        *{
          font-size: 40px;
        }
      }
      p{
        line-height: 32px;
        color: #fff;
        font-size: 16px;
         padding: 40px 50px 0 42px;
      }
    }
  }
  .browser-list{
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    padding-bottom: 25px;
    color: #fff;
    font-size: 17px;
    a{
      color: #fff;
      &:hover{
        color: #2f8ed2;
        text-decoration: underline;
      }
    }
  }
}
</style>