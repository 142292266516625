import {post} from '../index';

//登录
const userLogin = (query) => post('common/weblogin',query)

//修改密码
const updatePwdInfo = (query) => post('web/updatePwdInfo',query)

//添加活动
const saveActivity= (query) => post('admin/saveActivityInfo',query);
//活动列表
const userActivityList = (query) => post('admin/queryActivityPage',query);
//查询活动信息（修改）
const activityInfo = (query) => post('admin/queryActivityInfo',query);
//发布活动
const publishActivity = (query) => post('admin/publishActivityInfo',query);
//取消发布活动
const backPublishActivity = (query) => post('admin/backPublishActivityInfo',query);
//删除活动
const delActivityInfo = (query) => post('admin/delActivityInfo',query);
//活动下人员列表
const ActivityUsersList = (query) => post('admin/queryActivityUserPage',query);
//证书配置项列表
const certificateConfigList = (query) => post('admin/queryActivityCertInfo',query);
//保存证书配置列表
const saveConfig = (query,config) => post('admin/saveActivityCertInfo',query,config);
//管理员查看证书
const adminCheckCertificate = (query) => post('admin/queryUserCertDataById',query);



//我的证书
const userCertificateList = (query) => post('web/queryUserActivityList',query);

export {
    userLogin,
    updatePwdInfo,
    saveActivity,
    userActivityList,
    activityInfo,
    publishActivity,
    backPublishActivity,
    delActivityInfo,
    ActivityUsersList,
    certificateConfigList,
    saveConfig,
    adminCheckCertificate,
    userCertificateList
}
