<template>
  <div class="admin-certificate-manage bg-set">
    <Header>
      <template #navs>
        <div class="navs">
          <router-link to="/adminCertificateManage">证书管理</router-link>
        </div>
      </template>
    </Header>
    <div class="certificate-main" >
      <div class="btn-container-right">
        <el-button @click="addActivityFlag=true" class="theme-color">增加活动</el-button>
      </div>
      <template>
        <el-table
            :data="activityList"
            :cell-style="{textAlign:'center'}"
            :header-cell-style="{textAlign: 'center'}"
            border
            style="width: 100%">
          <el-table-column
              label="序号"
              width="65"
              type="index">
          </el-table-column>
          <el-table-column
              prop="yearName"
              label="年度"
              width="70">
          </el-table-column>
          <el-table-column
              prop="activityName"
              show-overflow-tooltip
              label="活动名称">
          </el-table-column>
          <el-table-column
              prop="companyName"
              show-overflow-tooltip
              label="举办单位">
          </el-table-column>
          <el-table-column
              width="90"
              label="证书状态">
            <template slot-scope="{row}">
              <span :class="row.publishStatusName==='未发布'?'color-red':'color-green'">{{row.publishStatusName}}</span>
            </template>
          </el-table-column>
          <el-table-column
              class-name="operate-column"
              prop="address"
              width="440"
              label="操作">
            <template slot-scope="{row}">
              <span @click="leapRouter('/adminTemplateConfig',row.id)">证书模板配置</span>
              <span @click="leapRouter('/adminMaintainData',row.id)">维护获奖名单</span>
              <span v-show="row.publishStatusName==='未发布'"  @click="publishActivity(row.id)">发布</span>
              <span v-show="row.publishStatusName==='已发布'"  @click="cancelPublishActivity(row.id)">取消发布</span>
              <i @click="editActivityInfo(row.id)"  class="el-icon-edit"></i>
              <i class="el-icon-delete" @click="deleteActivity(row.id)"></i>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <template>
        <div v-if="activityList && activityList.length"  class="block" style="text-align: right;margin-top: 30px;padding-bottom: 30px ">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageable.page"
              :page-sizes="pageSizes"
              :page-size="pageable.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </template>
    </div>
    <!--增加活动-->
    <AddActivity
        ref="addActivity"
        @addRefreshList="getActivityList"
        @closeAddActivity="addActivityFlag=false"
        :addActivityFlag="addActivityFlag">
    </AddActivity>

    <!--编辑活动-->
    <div v-if="editActivityFlag">
      <EditActivity
          ref="editActivity"
          @editRefreshList="getActivityList"
          @closeEditActivity="editActivityFlag=false"
          :editActivityFlag="editActivityFlag">
      </EditActivity>
    </div>
  </div>
</template>

<script>
import {userActivityList,publishActivity,backPublishActivity,delActivityInfo} from '../../request/api/common'

import Header from "../../components/Header";
import AddActivity from '../../components/AddActivity'
import EditActivity from '../../components/EditActivity'

export default {
  name: "activityList",
  components: {
    Header,
    AddActivity,
    EditActivity
  },
  data() {
    return {
      addActivityFlag: false,
      editActivityFlag:false,
      pageable:{
        page:1,
        size:10,
      },
      loading:true,
      total:0,
      pageSizes: [10, 15, 20, 30],
      activityList: []
    }
  },
  mounted() {
    this.getActivityList();
  },
  methods: {
    leapRouter(path,activityId) {
      this.$router.push({path,query:{activityId}});
    },
    getActivityList() {
      const pageable={page:this.pageable.page-1,size:this.pageable.size}
      userActivityList({...pageable}).then(({data})=>{
        this.activityList=data.page.content;
        this.total=parseInt(data.page.totalElements);
      })
    },
    editActivityInfo(id){
      this.editActivityFlag=true;
      this.$nextTick(()=>{
        this.$refs.editActivity.getActivityInfo(id);
      })
    },
    handleSizeChange(val) {
      this.pageable.size=val;
      this.pageable.page=1;
      this.getActivityList();
    },
    handleCurrentChange(val){
      this.pageable.page=val;
      this.getActivityList();
    },
    /*发布活动*/
    publishActivity(id){
      publishActivity({id}).then((m)=>{
        if(m.resultCode==-1){
          this.$message({
            message: m.data.msg,
            type: 'error'
          })
          return
        }else if(m.resultCode==0){
          this.$message({
            message: `${m.data.msg}`,
            type: 'success'
          });
          this.getActivityList();
        }
      })
    },
    /*取消发布*/
    cancelPublishActivity(id){
      this.$confirm('确认取消发布？')
          .then(() => {
            backPublishActivity({id}).then((m)=>{
              if(m.resultCode==-1){
                this.$message({
                  message: m.data.msg,
                  type: 'error'
                })
                return
              }else if(m.resultCode==0){
                this.$message({
                  message: `${m.data.msg}`,
                  type: 'success'
                });
                this.getActivityList();
              }
            })
          })

    },
    /*删除活动信息*/
    deleteActivity(id){
      this.$confirm('确认删除活动？')
          .then(() => {
            delActivityInfo({id}).then((m)=>{
              if(m.resultCode==-1){
                this.$message({
                  message: m.data.msg,
                  type: 'error'
                })
                return
              }else if(m.resultCode==0){
                this.$message({
                  message: `${m.data.msg}`,
                  type: 'success'
                });
                this.getActivityList();
              }
            })
          })



    },
  }
}
</script>

<style scoped lang="less">
.operate-column .cell {
  * {
    padding: 0 10px;
    cursor: pointer;
    color: #2f8ed2;
  }
}

.color-green {
  color: #67c23a;
}

.color-red {
  color: red;
}

</style>